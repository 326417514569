import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";

const AppDatePicker = ({ onChange, eventTracker, ...props }) => {
  const gaEventTracker = useAnalyticsEventTracker({
    category: "User Interaction"
  });

  const handleChange = event => {
    if (eventTracker) gaEventTracker(eventTracker.event, eventTracker.label);
    onChange(event);
  };
  return (
    <DatePicker
      onChange={handleChange}
      inputVariant={"outlined"}
      sx={{ width: "100%" }}
      {...props}
    />
  );
};

export default AppDatePicker;
