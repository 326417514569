import React from "react";
import PropTypes from "prop-types";

// @mui/material
import Grid from "@mui/material/Grid";

// @mui/material
// Core
import BaseLayout from "./BaseLayout";

export default function CenteredLayout({ children, footer }) {
  return (
    <BaseLayout footer={footer}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ minHeight: "calc(100vh - 244px)" }}
      >
        {children}
      </Grid>
    </BaseLayout>
  );
}

CenteredLayout.propTypes = {
  footer: PropTypes.bool
};

CenteredLayout.defaultProps = {
  footer: true
};
