import { useCookies } from "react-cookie";
import { useState } from "react";

export default function useUsers() {
  const [cookies, setCookie] = useCookies(["users"]);
  const [selectedUser, setSelectedUser] = useState(
    cookies.users ? cookies.users[0] : null
  );
  return {
    selectedUser,
    setSelectedUser,
    users: cookies.users,
    setUsers: users => {
      setCookie("users", users, { path: "/" });
    }
  };
}
