import React from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import { useHistory } from "react-router-dom";
import useAnalyticsEventTracker from "hooks/ga/useAnalyticsEventTracker";

import HeroSection from "./HeroSection";
import ProductsSection from "./ProductsSection";
import TestimonialsSection from "./TestimonialsSection";
import LandingCarousel from "./LandingCarousel";

export default function LandingView(props) {
  const gaEventTracker = useAnalyticsEventTracker({
    category: "User Interaction"
  });

  const history = useHistory();

  return (
    <CenteredLayout>
      <LandingCarousel />
    </CenteredLayout>
  );
}
