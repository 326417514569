import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import LandingView from "./views/Landing/LandingView";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import theme from "./theme";
import LogRocket from "logrocket";

import "./assets/fonts/RoxboroughCF.ttf";
import "./assets/fonts/RoxboroughCFThin.ttf";
import AboutUsView from "./views/AboutUs/AboutUsView";
import BirthChartReportView from "./views/Reports/BirthChartReportView";
import ConfirmedPaymentView from "./views/Reports/ConfirmedPaymentView";
import LoveReportView from "./views/Reports/LoveReportView";
import CouncelingView from "./views/Reports/CounselingView";
import ChatView from "./views/Chat";
import PDFExampleView from "./views/PDFExample/PDFExampleView";

import ReactGA from "react-ga";
ReactGA.initialize("G-KD1X4CG5ZJ");

if (process.env.NODE_ENV === "production") {
  // LogRocket.init("xawpdp/covid");
  // console.log("log rocket configured");
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const hist = createBrowserHistory();
root.render(
  <CookiesProvider>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <Router history={hist}>
          <Switch>
            <Route path="/payment/confirm" component={ConfirmedPaymentView} />
            <Route path="/counceling" component={CouncelingView} />
            <Route path="/reports/love" component={LoveReportView} />
            <Route
              path="/reports/birth-chart"
              component={BirthChartReportView}
            />
            <Route path="/about-us/" component={AboutUsView} />
            <Route path="/chat/" component={ChatView} />
            <Route
              path="/preview/love-pdf"
              render={() => <PDFExampleView pdfType="love_pdf_example" />}
            />
            <Route
              path="/preview/general-pdf"
              render={() => <PDFExampleView pdfType="general_pdf_example" />}
            />
            <Route
              path="/pdf-example"
              render={() => <PDFExampleView pdfType="general_pdf_example" />}
            />
            <Route path="/" component={LandingView} />
          </Switch>
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  </CookiesProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
