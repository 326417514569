import AspectIcon from "../../icons/AspectIcon";
import Grid from "@mui/material/Grid";
import React from "react";
import { styled } from "@mui/material";
import ObjectIcon from "../../icons/ObjectIcon";
import SvgButton from "../../Button/SvgButton";
import useObjects from "../../../hooks/astrology/useObjects";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { aspectColors } from "../../../assets/jss/rootStyle";
import Paper from "@mui/material/Paper";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import { SUN } from "../../../utils/constants";

const StyledCell = styled("div")(({ theme, leftStroke }) => ({
  height: "16px",
  fontSize: "0.9em",
  textAlign: "center",
  borderRight: "1px solid #aaaaaa",
  borderBottom: "1px solid #aaaaaa",
  borderLeft: leftStroke ? "1px solid #aaaaaa" : "none"
}));

const getAspectIconCellStyle = transparent => ({
  color: "#333333",
  display: "flex",
  height: "14px",
  padding: "2px",
  alignItems: "center",
  justifyContent: "space-around",
  "-webkit-transition": "opacity 0.4s ease-in-out",
  "-moz-transition": "opacity 0.4s ease-in-out",
  "-ms-transition": "opacity 0.4s ease-in-out",
  "-o-transition": "opacity 0.4s ease-in-out",
  transition: "opacity 0.4s ease-in-out",
  opacity: transparent ? 0.3 : 1
});

const StyledAspectCell = styled("div")(({ theme, transparent }) => ({
  color: "#333333",
  display: "flex",
  height: "12px",
  padding: "2px",
  alignItems: "center",
  justifyContent: "space-around",
  "-webkit-transition": "opacity 0.4s ease-in-out",
  "-moz-transition": "opacity 0.4s ease-in-out",
  "-ms-transition": "opacity 0.4s ease-in-out",
  "-o-transition": "opacity 0.4s ease-in-out",
  transition: "opacity 0.4s ease-in-out",
  opacity: transparent ? 0.3 : 1
}));

const StyledAspectContainer = styled("div")(({ theme, color }) => ({
  width: "100%",
  overflowX: "auto"
}));

const StyledPlanetRow = styled(Grid)(({ theme, color }) => ({
  "&:last-child": {
    flexGrow: 0
  }
  // "&:first-child $cell": {
  //   borderLeft: "1px solid #aaaaaa"
  // }
}));

const ASPECT_COLOR = {
  harmonic: aspectColors.harmonic.light,
  dynamic: aspectColors.dynamic.light,
  neutral: aspectColors.neutral.light
};
export default function AspectTable({
  chart,
  selectedPlanet,
  onSelectPlanet,
  btn
}) {
  const objects = useObjects();
  return (
    <>
      <Box mb={2}>
        <Paper>
          <Box p={2}>
            <Grid container justify={"space-between"}>
              <Grid item>
                <Typography variant={"h6"}>
                  Aspectos{" "}
                  {selectedPlanet
                    ? `de ${chart.getPlanet(selectedPlanet).name}`
                    : ""}
                </Typography>
              </Grid>
              {/* <Grid item>
                <MoreInfoButton />
              </Grid> */}
            </Grid>
            <StyledAspectContainer>
              <Grid container sx={{ flexWrap: "nowrap" }}>
                {objects.map((obj, i) => {
                  const ObjIcon = ObjectIcon[obj];
                  return (
                    <StyledPlanetRow key={obj} item xs>
                      {objects.slice(0, objects.length).map((obj2, j) => {
                        if (i > j)
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid transparent",
                                height: "16px"
                              }}
                              onClick={() => onSelectPlanet(null)}
                            ></div>
                          );
                        if (i === j)
                          return (
                            <div
                              style={{
                                borderBottom: "1px solid #aaaaaa",
                                textAlign: "center"
                              }}
                            >
                              {btn && (
                                <SvgButton
                                  icon={ObjIcon}
                                  onClick={() =>
                                    selectedPlanet === obj
                                      ? onSelectPlanet(null)
                                      : onSelectPlanet(obj)
                                  }
                                  color={
                                    obj == selectedPlanet
                                      ? "inherit"
                                      : "primary"
                                  }
                                  background={obj != selectedPlanet}
                                />
                              )}
                              {!btn && (
                                <SvgIcon
                                  component={ObjIcon}
                                  viewBox={"0 0 512 512"}
                                  fontSize="inherit"
                                />
                              )}
                            </div>
                          );
                        const aspect = chart.getAspect(obj, obj2);
                        const firstColumnWithStroke = obj === SUN && i === 0;
                        if (!aspect)
                          return (
                            <StyledCell
                              onClick={() => onSelectPlanet(null)}
                              leftStroke={firstColumnWithStroke}
                            >
                              <StyledAspectCell
                                transparent={
                                  selectedPlanet &&
                                  ![obj, obj2].includes(selectedPlanet)
                                }
                              >
                                -
                              </StyledAspectCell>
                            </StyledCell>
                          );
                        const aspectNature = chart.getAspectNature(aspect.type);
                        const AsIcon = AspectIcon[aspect.type];
                        const decimal = Math.round(
                          10 * (aspect.orb - Math.floor(aspect.orb))
                        );
                        return (
                          <StyledCell
                            key={obj2}
                            onClick={() => onSelectPlanet(null)}
                            title={chart.getAspectName(aspect.type)}
                            leftStroke={firstColumnWithStroke}
                          >
                            <div style={{ height: "12px" }}>
                              <AsIcon
                                width={10}
                                height={10}
                                fill={ASPECT_COLOR[aspectNature]}
                                // style={getAspectIconCellStyle(selectedPlanet && ![obj, obj2].includes(selectedPlanet))}
                                //strokeWidth={2}
                              />
                              <span>
                                {Math.round(aspect.orb)}
                                {false && <span>.{decimal}</span>}
                                {objects.length > 15 ? "" : "°"}
                              </span>
                            </div>
                          </StyledCell>
                        );
                      })}
                    </StyledPlanetRow>
                  );
                })}
              </Grid>
            </StyledAspectContainer>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
