import React from "react";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";

const StyledIconButton = styled(IconButton)(({ background }) => ({
  backgroundColor: background ? "#d7dcf7" : "transparent",
  border: background ? "1px solid #d7dcf7" : "1px solid transparent"
}));

export default function CustomIconButton({ background, ...props }) {
  return (
    <StyledIconButton
      size="small"
      color={"primary"}
      background={background}
      {...props}
    />
  );
}
