import { useCallback, useEffect, useState } from "react";
import axios from "utils/axios";
import camelcaseKeys from "camelcase-keys";
import useIsMountedRef from "./useIsMountedRef";
import PropTypes from "prop-types";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import useConstant from "use-constant";

const queryString = params =>
  Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join("&");

export default function useData({ url, lazy, queryParams, debounce }) {
  const isMountedRef = useIsMountedRef();
  const [state, setState] = useState({ item: null, loading: !lazy });

  const fetchItem = (_queryParams, _url) => {
    console.log(_queryParams, _url);
    setState({ ...state, loading: true });
    axios
      .get(_url || url, { params: _queryParams })
      .then(response => {
        console.log("responde_data", response.data);
        setState({
          ...state,
          item: response.data
            ? camelcaseKeys(response.data, { deep: true })
            : null,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        return setState({ ...state, loading: false });
      });
  };

  // Debounce the original search async function
  const debouncedFetch = useConstant(() =>
    AwesomeDebouncePromise(fetchItem, debounce || 0)
  );

  // const fetchAsync = useAsync(debouncedFetch, [queryParams]);
  useEffect(() => {
    if (url && !lazy) {
      debouncedFetch(queryParams, url);
    }
  }, [isMountedRef, url, queryString(queryParams || {})]);
  return {
    ...state,
    fetchItem: () => debouncedFetch(queryParams, url),
    updateItem: item => {
      setState({
        ...state,
        item: { ...item },
        loading: false
      });
    },
    removeItem: item => {
      setState({
        ...state,
        item: null,
        loading: false
      });
    }
  };
}

useData.propTypes = {
  queryParams: PropTypes.object,
  lazy: PropTypes.bool
};

useData.defaultProps = {
  queryParams: {},
  lazy: false
};
