import { ReactComponent as SextileIcon } from "assets/img/icons/zoodiac/svg/001-sextile.svg";
import { ReactComponent as SquareIcon } from "assets/img/icons/zoodiac/svg/002-square.svg";
import { ReactComponent as OppositionIcon } from "assets/img/icons/zoodiac/svg/003-opposition.svg";
import { ReactComponent as ConjunctionIcon } from "assets/img/icons/zoodiac/svg/004-conjuction.svg";
import { ReactComponent as TrineIcon } from "assets/img/icons/zoodiac/svg/016-fire.svg";
import { ReactComponent as DeathIcon } from "assets/img/icons/zoodiac/svg/010-death.svg";
import { ReactComponent as BiquintileIcon } from "assets/icons/astrology-biquintiles-bold-400w-300x300.svg";
import { ReactComponent as QuintileIcon } from "assets/icons/astrology-quintiles-bold-400w-300x300.svg";
import { ReactComponent as QuincunxIcon } from "assets/icons/astrology-inconjunctions-bold-400w-300x300.svg";
import { ReactComponent as SemisextileIcon } from "assets/icons/astrology-semi-sexties-bold-400w-300x300.svg";
import { ReactComponent as SemisquareIcon } from "assets/icons/astrology-semi-squares-bold-400w.svg";
import { ReactComponent as SesquisquareIcon } from "assets/icons/astrology-sesquiquadrates-bold-400w.svg";
import { ReactComponent as SemiQuintileIcon } from "assets/icons/astrology-semi-sexties-bold-400w-300x300.svg";
import {
  BIQUINTILE,
  CONJUNCTION,
  OPPOSITION,
  QUINCUNX,
  QUINTILE,
  SEMIQUINTILE,
  SEMISEXTILE,
  SEMISQUARE,
  SESQUIQUINTILE,
  SESQUISQUARE,
  SEXTILE,
  SQUARE,
  TRINE
} from "../../utils/constants";

export { SextileIcon, SquareIcon, OppositionIcon, ConjunctionIcon, TrineIcon };

export default {
  [CONJUNCTION]: ConjunctionIcon,
  [SEXTILE]: SextileIcon,
  [SQUARE]: SquareIcon,
  [TRINE]: TrineIcon,
  [OPPOSITION]: OppositionIcon,
  [SEMISEXTILE]: SemisextileIcon,
  [SEMIQUINTILE]: DeathIcon,
  [SEMISQUARE]: SemisquareIcon,
  [QUINTILE]: QuintileIcon,
  [SESQUIQUINTILE]: DeathIcon,
  [SESQUISQUARE]: SesquisquareIcon,
  [BIQUINTILE]: BiquintileIcon,
  [QUINCUNX]: QuincunxIcon
};
