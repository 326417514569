import React, { useState } from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import Container from "@mui/material/Container";

import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import reportImage from "assets/img/report_birthchart.png";
import useQuery from "../../hooks/query/useQuery";

export default function ConfirmedPaymentView(props) {
  const query = useQuery();
  const { email, order } = query;
  return (
    <CenteredLayout>
      <Container maxWidth={"lg"}>
        <Card>
          <CardContent>
            <Box textAlign="left" p={3}>
              <Grid container justify={"flex-start"} spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box textAlign="left">
                    <Typography variant="h4" color="textPrimary">
                      ¡Compra completa!
                    </Typography>
                    <Divider />
                    <Box textAlign="center" marginTop={"10px"} />
                    <Typography variant="h5" color="textPrimary">
                      N° de orden: {order}
                    </Typography>
                    <Box textAlign="center" marginTop={"10px"} />
                    <Typography variant="body1" color="textPrimary">
                      En menos de 48 horas enviaremos el reporte PDF al email{" "}
                      <strong>{email}</strong>
                    </Typography>
                    <Box textAlign="center" marginTop={"10px"} />
                    <Typography variant="body1" color="textPrimary">
                      Si tienes algun problema con tu compra, tienes alguna duda
                      sobre el reporte astrológico o cualquier otra, no dudes en
                      escribirnos en{" "}
                      <a href="https://www.instagram.com/astrall.cl/">
                        @astrall.cl
                      </a>{" "}
                      o a{" "}
                      <a href="mailto:ednar.echev@gmail.com">
                        ednar.echev@gmail.com
                      </a>
                      .
                    </Typography>
                  </Box>
                  <Box textAlign="center" marginTop={"30px"} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div>
                    <img
                      src={reportImage}
                      alt="report"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </CenteredLayout>
  );
}
