import { ReactComponent as SunIcon } from "assets/img/icons/zoodiac/svg/043-sun.svg";
import { ReactComponent as MoonIcon } from "assets/img/icons/zoodiac/svg/042-moon.svg";
import { ReactComponent as MercuryIcon } from "assets/img/icons/zoodiac/svg/040-mercury.svg";
import { ReactComponent as VenusIcon } from "assets/img/icons/zoodiac/svg/039-venus.svg";
import { ReactComponent as MarsIcon } from "assets/img/icons/zoodiac/svg/038-mars.svg";
import { ReactComponent as JupiterIcon } from "assets/img/icons/zoodiac/svg/037-jupiter.svg";
import { ReactComponent as SaturnIcon } from "assets/img/icons/zoodiac/svg/036-saturn.svg";
import { ReactComponent as UranusIcon } from "assets/img/icons/zoodiac/svg/035-uranus.svg";
import { ReactComponent as NeptureIcon } from "assets/img/icons/zoodiac/svg/033-neptune.svg";
import { ReactComponent as PlutoIcon } from "assets/img/icons/zoodiac/svg/032-pluto.svg";
import {
  JUPITER,
  MARS,
  MERCURY,
  MOON,
  NEPTUNE,
  PLUTO,
  SATURN,
  SUN,
  URANUS,
  VENUS
} from "../../utils/constants";

export {
  SunIcon,
  MoonIcon,
  MercuryIcon,
  VenusIcon,
  MarsIcon,
  JupiterIcon,
  SaturnIcon,
  UranusIcon,
  NeptureIcon,
  PlutoIcon
};

export default {
  [SUN]: SunIcon,
  [MOON]: MoonIcon,
  [MERCURY]: MercuryIcon,
  [VENUS]: VenusIcon,
  [MARS]: MarsIcon,
  [JUPITER]: JupiterIcon,
  [SATURN]: SaturnIcon,
  [URANUS]: UranusIcon,
  [NEPTUNE]: NeptureIcon,
  [PLUTO]: PlutoIcon
};
