import React from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import Container from "@mui/material/Container";

import { Card, CardContent, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";

export default function AboutUsView(props) {
  return (
    <CenteredLayout>
      <Container maxWidth={"lg"}>
        <Card>
          <CardContent>
            <Box textAlign="center">
              <Grid container justifyContent={"center"} spacing={3}>
                <Grid item xs={10}>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Box textAlign="center">
                        <Typography variant="h3" color="textPrimary">
                          ¿Qué es Astrall?
                        </Typography>
                        <Box textAlign="center" marginTop={"10px"} />
                        <Typography variant="body1" color="textPrimary">
                          <strong>
                            Astrall nació como un proyecto para democratizar la
                            astrología
                          </strong>{" "}
                          haciéndola accesible a cualquier interesada{"/"}o de
                          manera sencilla y práctica.
                          <br />
                          <br />
                        </Typography>
                      </Box>
                      <Box textAlign="center" marginTop={"30px"} />
                      <Box textAlign="center">
                        <Box textAlign="center" marginTop={"10px"} />
                        <Typography variant="body1" color="textPrimary">
                          En Astrall vemos la astrología como una poderosa
                          herramienta, que puede ser aprovechada para el
                          auto-descubrimiento y crecimiento personal. También
                          entendemos que algunos lo suelen abordar con fines
                          recreativos o de ocio.
                        </Typography>
                      </Box>
                      <Box textAlign="center" marginTop={"30px"} />
                    </Grid>
                    <Grid item>
                      <Box textAlign="center">
                        <Typography variant="subtitle1">
                          ¿Tienes alguna pregunta?
                        </Typography>
                        <Typography variant="body2">
                          <a
                            href="https://www.instagram.com/astrall.cl/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            @astrall.cl
                          </a>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </CenteredLayout>
  );
}
