import useData from "./useData";

export default function useNatalChart({ queryParams, lazy }) {
  const { item, loading, updateItem, removeItem } = useData({
    url: `/build-chart/`,
    queryParams,
    lazy
  });

  return {
    natalChart: item,
    loading,
    updateItem,
    removeItem
  };
}
