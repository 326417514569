import { ReactComponent as AriesIcon } from "assets/img/icons/zoodiac/svg/056-aries.svg";
import { ReactComponent as TaurusIcon } from "assets/img/icons/zoodiac/svg/055-taurus.svg";
import { ReactComponent as GeminiIcon } from "assets/img/icons/zoodiac/svg/054-gemini.svg";
import { ReactComponent as CancerIcon } from "assets/img/icons/zoodiac/svg/053-cancer.svg";
import { ReactComponent as LeoIcon } from "assets/img/icons/zoodiac/svg/052-leo.svg";
import { ReactComponent as VirgoIcon } from "assets/img/icons/zoodiac/svg/051-virgo.svg";
import { ReactComponent as LibraIcon } from "assets/img/icons/zoodiac/svg/050-libra.svg";
import { ReactComponent as ScorpioIcon } from "assets/img/icons/zoodiac/svg/049-scorpio.svg";
import { ReactComponent as SagittariusIcon } from "assets/img/icons/zoodiac/svg/047-sagittarius.svg";
import { ReactComponent as CapricornIcon } from "assets/img/icons/zoodiac/svg/046-capricorn.svg";
import { ReactComponent as AquariusIcon } from "assets/img/icons/zoodiac/svg/045-aquarius.svg";
import { ReactComponent as PiscesIcon } from "assets/img/icons/zoodiac/svg/044-pisces.svg";
import {
  AQUARIUS,
  ARIES,
  CANCER,
  CAPRICORN,
  GEMINI,
  LEO,
  LIBRA,
  PISCES,
  SAGITTARIUS,
  SCORPIO,
  TAURUS,
  VIRGO
} from "../../utils/constants";

export {
  AriesIcon,
  TaurusIcon,
  GeminiIcon,
  CancerIcon,
  LeoIcon,
  VirgoIcon,
  LibraIcon,
  ScorpioIcon,
  SagittariusIcon,
  CapricornIcon,
  AquariusIcon,
  PiscesIcon
};

export default {
  [ARIES]: AriesIcon,
  [TAURUS]: TaurusIcon,
  [GEMINI]: GeminiIcon,
  [CANCER]: CancerIcon,
  [LEO]: LeoIcon,
  [VIRGO]: VirgoIcon,
  [LIBRA]: LibraIcon,
  [SCORPIO]: ScorpioIcon,
  [SAGITTARIUS]: SagittariusIcon,
  [CAPRICORN]: CapricornIcon,
  [AQUARIUS]: AquariusIcon,
  [PISCES]: PiscesIcon
};
