import useData from "../axios/useData";

export default function useGeoLocator({ queryParams }) {
  const { item, loading, fetchItem } = useData({
    url: "/geo-city/",
    queryParams,
    lazy: true,
    debounce: 700
  });
  const canBeFetched = query => query.length > 5;
  return { data: item, loading, fetchItem, canBeFetched };
}
