// Basic constans

// Four Elements
const FIRE = "Fire";
const EARTH = "Earth";
const AIR = "Air";
const WATER = "Water";

// Four Temperaments
const CHOLERIC = "Choleric";
const MELANCHOLIC = "Melancholic";
const SANGUINE = "Sanguine";
const PHLEGMATIC = "Phlegmatic";

// Genders
const MASCULINE = "Masculine";
const FEMININE = "Feminine";
const NEUTRAL = "Neutral";

// Signs

const ARIES = "Aries";
const TAURUS = "Taurus";
const GEMINI = "Gemini";
const CANCER = "Cancer";
const LEO = "Leo";
const VIRGO = "Virgo";
const LIBRA = "Libra";
const SCORPIO = "Scorpio";
const SAGITTARIUS = "Sagittarius";
const CAPRICORN = "Capricorn";
const AQUARIUS = "Aquarius";
const PISCES = "Pisces";

const SIGN_IDS = [
  ARIES,
  TAURUS,
  GEMINI,
  CANCER,
  LEO,
  VIRGO,
  LIBRA,
  SCORPIO,
  SAGITTARIUS,
  CAPRICORN,
  AQUARIUS,
  PISCES
];

const SIGN_NAMES = {
  [ARIES]: "Aries",
  [TAURUS]: "Tauro",
  [GEMINI]: "Gemini",
  [CANCER]: "Cancer",
  [LEO]: "Leo",
  [VIRGO]: "Virgo",
  [LIBRA]: "Libra",
  [SCORPIO]: "Sagitario",
  [SAGITTARIUS]: "Escorpio",
  [CAPRICORN]: "Capricornio",
  [AQUARIUS]: "Acuario",
  [PISCES]: "Piscis"
};

// Sign modes
const CARDINAL = "Cardinal";
const FIXED = "Fixed";
const MUTABLE = "Mutable";

const SUN = "Sun";
const MOON = "Moon";
const MERCURY = "Mercury";
const VENUS = "Venus";
const MARS = "Mars";
const JUPITER = "Jupiter";
const SATURN = "Saturn";
const URANUS = "Uranus";
const NEPTUNE = "Neptune";
const PLUTO = "Pluto";
const NORTH_NODE = "North Node";
const SOUTH_NODE = "South Node";
const SYZYGY = "Syzygy";
const PARS_FORTUNA = "Pars Fortuna";
const NO_PLANET = "None";

// Asteroids
const CHIRON = "Chiron";
const PHOLUS = "Pholus";
const CERES = "Ceres";
const PALLAS = "Pallas";
const JUNO = "Juno";
const VESTA = "Vesta";

const ASTEROID_LIST = [CHIRON, PHOLUS, CERES, PALLAS, JUNO, VESTA];

const PLANET_LIST = [
  SUN,
  MOON,
  MERCURY,
  VENUS,
  MARS,
  JUPITER,
  SATURN,
  URANUS,
  NEPTUNE,
  PLUTO
];

const PLANET_NAMES = {
  [SUN]: "Sol",
  [MOON]: "Luna",
  [MERCURY]: "Mercurio",
  [VENUS]: "Venus",
  [MARS]: "Marte",
  [JUPITER]: "Jupiter",
  [SATURN]: "Saturno",
  [URANUS]: "Urano",
  [NEPTUNE]: "Neptuno",
  [PLUTO]: "Plutón"
};

// Angles

const ASC = "Asc";
const DESC = "Desc";
const MC = "MC";
const IC = "IC";

const ANGLES_LIST = [ASC, DESC, MC, IC];

const ANGLES_NAMES = {
  [ASC]: "Ascendente",
  [DESC]: "Descendente",
  [MC]: "Medio Cielo",
  [IC]: "Bajo Cielo"
};

// Object movement
const DIRECT = "Direct";
const RETROGRADE = "Retrogade";
const STATIONARY = "Stationary";

const OBJECT_NAMES = {
  ...PLANET_NAMES,
  [CHIRON]: "Quirón",
  [PHOLUS]: "Pholus",
  [CERES]: "Ceres",
  [PALLAS]: "Pallas",
  [JUNO]: "Juno",
  [VESTA]: "Vesta",
  [NORTH_NODE]: "Nodo Norte",
  [SOUTH_NODE]: "Nodo Sur",
  ...ANGLES_NAMES
};

// Object type
const OBJ_PLANET = "Planet";
const OBJ_HOUSE = "House";
const OBJ_MOON_NODE = "Moon Node";
const OBJ_ARABIC_PART = "Arabic Part";
const OBJ_FIXED_STAR = "Fixed Star";
const OBJ_ASTEROID = "Asteroid";
const OBJ_LUNATION = "Lunation";
const OBJ_GENERIC = "Generic";

const LIST_OBJECTS_TRADITIONAL = [
  SUN,
  MOON,
  MERCURY,
  VENUS,
  MARS,
  JUPITER,
  SATURN,
  NORTH_NODE,
  SOUTH_NODE,
  SYZYGY,
  PARS_FORTUNA
];

const LIST_OBJECTS_MODERN = [
  SUN,
  MOON,
  MERCURY,
  VENUS,
  MARS,
  JUPITER,
  SATURN,
  URANUS,
  NEPTUNE,
  PLUTO,
  NORTH_NODE,
  SOUTH_NODE,
  SYZYGY,
  PARS_FORTUNA
];

const LIST_SEVEN_PLANETS = [SUN, MOON, MERCURY, VENUS, MARS, JUPITER, SATURN];
const LIST_TEN_PLANETS = [
  SUN,
  MOON,
  MERCURY,
  VENUS,
  MARS,
  JUPITER,
  SATURN,
  URANUS,
  NEPTUNE,
  PLUTO
];

// === Aspects === */

// Major Aspects
const NO_ASPECT = -1;
const CONJUNCTION = "0";
const SEXTILE = "60";
const SQUARE = "90";
const TRINE = "120";
const OPPOSITION = "180";

// Minor Aspects
const SEMISEXTILE = "30";
const SEMIQUINTILE = "36";
const SEMISQUARE = "45";
const QUINTILE = "72";
const SESQUIQUINTILE = "108";
const SESQUISQUARE = "135";
const BIQUINTILE = "144";
const QUINCUNX = "150";

// Aspect movement
const APPLICATIVE = "Applicative";
const SEPARATIVE = "Separative";
const EXACT = "Exact";
const NO_MOVEMENT = "None";

// Aspect direction
const DEXTER = "Dexter"; // Right side
const SINISTER = "Sinister"; // Left side

// Aspect properties
const ASSOCIATE = "Associate";
const DISSOCIATE = "Dissociate";

// Aspect lists
const MAJOR_ASPECTS = [CONJUNCTION, SEXTILE, SQUARE, TRINE, OPPOSITION];
const MINOR_ASPECTS = [
  SEMISEXTILE,
  SEMIQUINTILE,
  SEMISQUARE,
  QUINTILE,
  SESQUIQUINTILE,
  SESQUISQUARE,
  BIQUINTILE,
  QUINCUNX
];
const ALL_ASPECTS = [...MAJOR_ASPECTS, ...MINOR_ASPECTS];

const HARMONIC_ASPECTS = [SEXTILE, TRINE];

const DYNAMIC_ASPECTS = [SQUARE, OPPOSITION];

const NEUTRAL_ASPECTS = [CONJUNCTION];

const HARMONIC_NATURE = "harmonic";
const DYNAMIC_NATURE = "dynamic";
const NEUTRAL_NATURE = "neutral";
const NO_NATURE = "no_nature";

// Aspect names
const ASPECT_NAMES = {
  [CONJUNCTION]: "Conjunción",
  [SEXTILE]: "Sextil",
  [SQUARE]: "Cuadratura",
  [TRINE]: "Trígono",
  [OPPOSITION]: "Oposición",
  [SEMISEXTILE]: "Semi-sextil",
  [SEMIQUINTILE]: "Semi-quintil",
  [SEMISQUARE]: "Semi-cuadratura",
  [QUINTILE]: "Quintil",
  [SESQUIQUINTILE]: "Sesqui-quintil",
  [SESQUISQUARE]: "Sesqui-cuadratura",
  [BIQUINTILE]: "Bi-quintil",
  [QUINCUNX]: "Quincuncio"
};

// Houses

const HOUSES_PLACIDUS = "Placidus";
const HOUSES_KOCH = "Koch";
const HOUSES_PORPHYRIUS = "Porphyrius";
const HOUSES_REGIOMONTANUS = "Regiomontanus";
const HOUSES_CAMPANUS = "Campanus";
const HOUSES_EQUAL = "Equal";
const HOUSES_EQUAL_2 = "Equal 2";
const HOUSES_VEHLOW_EQUAL = "Vehlow Equal";
const HOUSES_WHOLE_SIGN = "Whole Sign";
const HOUSES_MERIDIAN = "Meridian";
const HOUSES_AZIMUTHAL = "Azimuthal";
const HOUSES_POLICH_PAGE = "Polich Page";
const HOUSES_ALCABITUS = "Alcabitus";
const HOUSES_MORINUS = "Morinus";
const HOUSES_DEFAULT = HOUSES_PLACIDUS;

const HOUSE_SYS_LIST = [
  HOUSES_PLACIDUS,
  HOUSES_KOCH,
  HOUSES_PORPHYRIUS,
  HOUSES_REGIOMONTANUS,
  HOUSES_CAMPANUS,
  HOUSES_EQUAL,
  HOUSES_EQUAL_2,
  HOUSES_VEHLOW_EQUAL,
  HOUSES_WHOLE_SIGN,
  HOUSES_MERIDIAN,
  HOUSES_AZIMUTHAL,
  HOUSES_POLICH_PAGE,
  HOUSES_ALCABITUS,
  HOUSES_MORINUS
];

// ELEMENT Score
const OBJECT_SCORE = {
  [SUN]: 2,
  [MOON]: 2,
  [MERCURY]: 1.5,
  [VENUS]: 1.5,
  [MARS]: 1.5,
  [JUPITER]: 1,
  [SATURN]: 1,
  [URANUS]: 1,
  [NEPTUNE]: 1,
  [PLUTO]: 1,
  [ASC]: 1
};

export {
  SIGN_IDS,
  SIGN_NAMES,
  PLANET_LIST,
  PLANET_NAMES,
  OBJECT_NAMES,
  LIST_OBJECTS_TRADITIONAL,
  LIST_OBJECTS_MODERN,
  ASPECT_NAMES,
  MINOR_ASPECTS,
  MAJOR_ASPECTS,
  ALL_ASPECTS,
  MOON,
  SUN,
  MERCURY,
  VENUS,
  MARS,
  JUPITER,
  SATURN,
  URANUS,
  NEPTUNE,
  PLUTO,
  NORTH_NODE,
  SOUTH_NODE,
  CHIRON,
  PHOLUS,
  CERES,
  JUNO,
  VESTA,
  PALLAS,
  ASTEROID_LIST,
  ARIES,
  TAURUS,
  GEMINI,
  CANCER,
  LEO,
  VIRGO,
  LIBRA,
  SCORPIO,
  SAGITTARIUS,
  CAPRICORN,
  AQUARIUS,
  PISCES,
  ASC,
  DESC,
  MC,
  IC,
  ANGLES_LIST,
  ANGLES_NAMES,
  OBJECT_SCORE,
  CONJUNCTION,
  SEXTILE,
  SQUARE,
  TRINE,
  OPPOSITION,
  SEMISEXTILE,
  SEMIQUINTILE,
  SEMISQUARE,
  QUINTILE,
  SESQUIQUINTILE,
  SESQUISQUARE,
  BIQUINTILE,
  QUINCUNX,
  HARMONIC_ASPECTS,
  DYNAMIC_ASPECTS,
  NEUTRAL_ASPECTS,
  HARMONIC_NATURE,
  DYNAMIC_NATURE,
  NEUTRAL_NATURE,
  NO_NATURE,
  HOUSE_SYS_LIST,
  HOUSES_DEFAULT,
  FIRE,
  EARTH,
  AIR,
  WATER,
  CHOLERIC,
  MELANCHOLIC,
  SANGUINE,
  PHLEGMATIC,
  MASCULINE,
  FEMININE,
  NEUTRAL,
  CARDINAL,
  FIXED,
  MUTABLE,
  OBJ_PLANET,
  OBJ_HOUSE,
  OBJ_MOON_NODE,
  OBJ_ARABIC_PART,
  OBJ_FIXED_STAR,
  OBJ_ASTEROID,
  OBJ_LUNATION,
  OBJ_GENERIC,
  DIRECT,
  RETROGRADE,
  STATIONARY
};
