import ReactGA from "react-ga";

const useAnalyticsEventTracker = ({ category }) => {
  const eventTracker = (action = "action", label = "label") => {
    ReactGA.event({ category, action, label });
  };
  return process.env.NODE_ENV === "development"
    ? (action, label) => {
        console.log(`DEBUG GA Event: ${category} - ${action} - ${label}`);
      }
    : eventTracker;
};

export default useAnalyticsEventTracker;
