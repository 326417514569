import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Add from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import MomentUtils from "@date-io/moment";
import GeoLocalizerInput from "../../../components/Input/GeoLocalizerInput";
import DialogActions from "@mui/material/DialogActions";
import { QUINCUNX } from "../../../utils/constants";
import moment from "moment";
import useAnalyticsEventTracker from "../../../hooks/ga/useAnalyticsEventTracker";

export default function UserSelect({
  users,
  selected,
  setSelected,
  hideAddButton,
  open,
  onClose,
  setOpen
}) {
  const history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker({
    category: "User Interaction"
  });

  const [cookies, setCookie] = useCookies(["users"]);
  const [location, setLocation] = useState(null);
  const [locationQuery, setQuery] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  // useEffect for updating user data with selected user
  useEffect(() => {
    if (selected) {
      setName(selected.name);
      setEmail(selected.email);
      // convert date and time to moment objects
      setDate(selected.date ? moment(selected.date, "YYYY-MM-DD") : null);
      setTime(selected.time ? moment(selected.time, "HH:mm") : null);
      setLocation(
        selected.location
          ? {
              lat: selected.lat,
              lon: selected.lon,
              displayName: selected.location || null
            }
          : null
      );
    } else {
      setName("");
      setEmail("");
      setDate(null);
      setTime(null);
      setLocation(null);
    }
  }, [selected]);

  const handleChangeGaEventWrapper = (fn, { event, label }) => {
    return e => {
      fn(e);
      gaEventTracker(event, label);
    };
  };

  const handleChangeWrapper = (fn, { event, label }) => {
    return handleChangeGaEventWrapper(
      e => {
        fn(e.target.value);
      },
      { event, label }
    );
  };

  const handleAdd = e => {
    e.stopPropagation();
    setSelected(null);
    setOpen(true);
  };
  const handleAddUser = e => {
    const newUser = {
      name: name,
      date: date?.format("YYYY-MM-DD"),
      time: time?.format("HH:mm"),
      lat: location?.lat,
      lon: location?.lon,
      location: location?.displayName,
      email: email,
      minor_aspects: QUINCUNX,
      nodes: true
    };

    let newUsers;
    // Replaces selected user if there is one selected
    if (selected) {
      newUsers = users.map(user => {
        if (user.name === selected.name) {
          return newUser;
        } else {
          return user;
        }
      });
      gaEventTracker("form_submit", "user_create_form - user_select_form");
    } else {
      // Add new user to users
      newUsers = users ? [newUser, ...users] : [newUser];
      gaEventTracker("form_submit", "user_update_form - user_select_form");
    }
    setSelected(newUser);
    setCookie("users", newUsers, { path: "/" });
    onClose();
  };
  const showSelect = Boolean(users.length);
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Añade tu información personal</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant={"body1"} color={"textPrimary"}>
                Escribe tu nombre y email para poder hacer tu consulta
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Nombre"
                name={"name"}
                variant="outlined"
                value={name}
                onChange={handleChangeWrapper(setName, {
                  event: "input_change",
                  label: "name - user_select_form"
                })}
                disabled={selected}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="E-mail"
                name={"email"}
                variant="outlined"
                value={email}
                onChange={handleChangeWrapper(setEmail, {
                  event: "input_change",
                  label: "email - user_select_form"
                })}
                disabled={selected}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {!selected && (
                <Typography variant={"body1"} color={"textPrimary"}>
                  (Opcional) Compártenos tu información de nacimiento para
                  calcular tu carta natal y darte consejos astrológicos
                  personalizados.
                </Typography>
              )}
              {selected && !selected.date && (
                <Alert severity="warning">
                  Añade tu fecha y hora de nacimiento para recibir consejos
                  astrológicos personalizados
                </Alert>
              )}
              {selected && selected.date && !selected.time && (
                <Alert severity="warning">
                  Añade tu hora de nacimiento para recibir consejos astrológicos
                  personalizados
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Fecha (DD/MM/YYYY)"
                format="DD/MM/YYYY"
                value={date}
                onChange={handleChangeGaEventWrapper(setDate, {
                  event: "input_change",
                  label: "date - user_select_form"
                })}
                KeyboardButtonProps={{
                  "aria-label": "Cambiar fecha"
                }}
                inputVariant={"outlined"}
                fullWidth
                disableFuture
                disabled={selected?.date}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TimePicker
                sx={{ width: "100%" }}
                ampm={false}
                label="Hora"
                value={time}
                onChange={handleChangeGaEventWrapper(setTime, {
                  event: "input_change",
                  label: "time - user_select_form"
                })}
                KeyboardButtonProps={{
                  "aria-label": "Cambiar hora"
                }}
                inputVariant={"outlined"}
                fullWidth
                disabled={selected?.time}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <GeoLocalizerInput
                location={location}
                query={locationQuery}
                onQueryChange={setQuery}
                onLocationChange={handleChangeGaEventWrapper(setLocation, {
                  event: "input_change",
                  label: "location - user_select_form"
                })}
                disabled={selected?.location}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant={"contained"}
            color="secondary"
            size={"large"}
          >
            Cerrar
          </Button>
          <Button
            onClick={handleAddUser}
            variant={"contained"}
            color="primary"
            size={"large"}
            disabled={!name || !email || (!date && time) || (date && !location)}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      {showSelect && (
        <TextField
          select
          fullWidth
          variant={"outlined"}
          size={"small"}
          value={selected || { name: "Anónim@" }}
          onChange={e => setSelected(e.target.value)}
          SelectProps={{
            renderValue: value => (
              <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                <Typography variant="inherit">{value.name}</Typography>
                <Box flexGrow={1} />
                <div>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    <small>{`${value.date || ""} ${value.time || ""}`}</small>
                  </Typography>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    <small>{value.location || ""}</small>
                  </Typography>
                </div>
              </Box>
            )
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            )
          }}
        >
          {users.map(user => (
            <MenuItem key={user.name} value={user}>
              <Typography variant={"inherit"}>{user.name}</Typography>
              <Box flexGrow={1} />
              <Typography
                variant={"body2"}
                color={"textSecondary"}
              >{`${user.date || ""} ${user.time || ""}`}</Typography>
            </MenuItem>
          ))}
          {!hideAddButton && (
            <MenuItem onClick={handleAdd}>
              <Button variant={"text"} color={"primary"} onClick={handleAdd}>
                <Add />
                Agregar persona
              </Button>
            </MenuItem>
          )}
        </TextField>
      )}
    </>
  );
}
