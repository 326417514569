import {
  AQUARIUS,
  ARIES,
  ASC,
  ASPECT_NAMES,
  CANCER,
  CAPRICORN,
  DYNAMIC_ASPECTS,
  DYNAMIC_NATURE,
  GEMINI,
  HARMONIC_ASPECTS,
  HARMONIC_NATURE,
  LEO,
  LIBRA,
  NEUTRAL_ASPECTS,
  NEUTRAL_NATURE,
  NO_NATURE,
  OBJECT_SCORE,
  PISCES,
  OBJECT_NAMES,
  SAGITTARIUS,
  SCORPIO,
  SIGN_NAMES,
  TAURUS,
  VIRGO,
  ASTEROID_LIST,
  ANGLES_LIST,
  SIGN_IDS,
  LIST_OBJECTS_MODERN,
  ANGLES_NAMES,
  OBJ_PLANET
} from "../utils/constants";

class ElementCalculator {
  constructor(chart, score = {}) {
    this.score = { ...OBJECT_SCORE, ...score };
    this.chart = chart;
  }

  /**
   * Return planet score contribution for each element.
   * @param planetId
   */
  getPlanetScore = planetId => {
    if (planetId === ASC) return {};
    const planet = this.chart.getPlanet(planetId);
    const sign = this.chart.getPlanetSign(planetId);
    if (planet.signlon < 29) return { [sign.element]: this.score[planetId] };
    else {
      const score = this.score[planetId] / 2;
      const nextSign = this.chart.getNextSign(sign.id);
      return { [sign.element]: score, [nextSign.element]: score };
    }
  };
  getTotalScore = () => {
    const score = { fire: 0, aqua: 0, air: 0, earth: 0 };
    Object.keys(this.score).map(planetId => {
      const planetScore = this.getPlanetScore(planetId);
      Object.keys(planetScore).map(
        element => (score[element] = score[element] + planetScore[element])
      );
    });
    return score;
  };
}

const SIGNS_DATA = [
  { name: "Aries", value: 30, element: "fire", id: ARIES },
  { name: "Tauro", value: 30, element: "earth", id: TAURUS },
  { name: "Geminis", value: 30, element: "air", id: GEMINI },
  { name: "Cancer", value: 30, element: "aqua", id: CANCER },
  { name: "Leo", value: 30, element: "fire", id: LEO },
  { name: "Virgo", value: 30, element: "earth", id: VIRGO },
  { name: "Libra", value: 30, element: "air", id: LIBRA },
  { name: "Escorpio", value: 30, element: "aqua", id: SCORPIO },
  { name: "Sagitario", value: 30, element: "fire", id: SAGITTARIUS },
  { name: "Capricornio", value: 30, element: "earth", id: CAPRICORN },
  { name: "Acuario", value: 30, element: "air", id: AQUARIUS },
  { name: "Piscis", value: 30, element: "aqua", id: PISCES }
];

const aspect_sort = (a, b) => {
  const order = [...NEUTRAL_ASPECTS, ...HARMONIC_ASPECTS, ...DYNAMIC_ASPECTS];
  const aI = order.indexOf(a.type);
  const bI = order.indexOf(b.type);
  if (aI === -1 && bI === -1) return parseInt(a.type) - parseInt(b.type);
  if (aI === -1) return 1;
  if (bI === -1) return -1;
  return aI - bI;
};

export class MockNatalChart {
  date = "2019-01-01";
  time = "00:00";
  getPlanetSign = id => {
    return SIGNS_DATA[0];
  };
  getAscendantSign = () => {
    return SIGNS_DATA[0];
  };
}

export default class NatalChart {
  constructor(
    date,
    time,
    lat,
    lon,
    { objects, houses, aspects, signsAngle, angles }
  ) {
    this.date = date;
    this.time = time;
    this.lat = lat;
    this.lon = lon;
    this.aspects = aspects;
    this.signs = SIGNS_DATA;
    this.objects = this.parseLonAsFloat(objects);
    this.houses = this.parseLonAsFloat(houses);
    this.angles = this.parseLonAsFloat(angles);
    this.signsAngle = signsAngle;

    const compare = (a, b) => {
      if (a.lon > b.lon) return 1;
      if (b.lon > a.lon) return -1;

      return 0;
    };
    this.objects.sort(compare);
  }

  parseLonAsFloat = data =>
    data.map(({ lon, signlon, ...d }) => ({
      ...d,
      lon: parseFloat(lon),
      signlon: parseFloat(signlon)
    }));

  getNextSign(signId) {
    const index = this.signs.findIndex(s => s.id === signId);
    return this.signs[(index + 1) % 12];
  }

  getPlanetsInSign(signId) {
    return this.objects.filter(p => p.sign === signId);
  }

  getPlanetsInHouse(house) {
    return this.objects.filter(p => p.house === house);
  }

  getPlanetAngle(planet) {
    return this.getPlanet(planet).signlon;
  }

  getPlanetSign(planet) {
    planet = this.getPlanet(planet);
    return this.signs.find(s => s.id === planet.sign);
  }

  getPlanetAspects(planet) {
    planet = this.getPlanet(planet);
    const aspects = [
      ...this.aspects
        .filter(a => a.active.id === planet.id)
        .map(({ active, passive, ...a }) => ({
          planet: passive.id,
          ...a,
          name: this.getAspectName(a.type)
        })),
      ...this.aspects
        .filter(a => a.passive.id === planet.id)
        .map(({ active, passive, ...a }) => ({
          planet: active.id,
          ...a,
          name: this.getAspectName(a.type)
        }))
    ];
    aspects.sort(aspect_sort);
    return aspects;
  }

  getObjectAspects(object) {
    const aspects = [
      ...this.aspects
        .filter(a => a.active.id === object)
        .map(({ active, passive, ...a }) => ({
          object: passive.id,
          ...a,
          name: this.getAspectName(a.type)
        })),
      ...this.aspects
        .filter(a => a.passive.id === object)
        .map(({ active, passive, ...a }) => ({
          object: active.id,
          ...a,
          name: this.getAspectName(a.type)
        }))
    ];
    aspects.sort(aspect_sort);
    return aspects;
  }

  getAspect(obj1, obj2) {
    return this.aspects.find(
      aspect =>
        (aspect.active.id === obj1 && aspect.passive.id === obj2) ||
        (aspect.active.id === obj2 && aspect.passive.id === obj1)
    );
  }

  getPlanet(planet) {
    let obj = planet;
    if (typeof planet === "string") {
      obj = this.objects.find(p => p.id === planet);
    }
    return {
      ...obj,
      name: OBJECT_NAMES[obj.id],
      houseDisplay: this.getObjectHouseDisplay(obj)
    };
  }

  getObjectHouseDisplay(planet) {
    const house = this.getHouse(planet.house);
    if (planet.lon < house.lon) {
      return `${planet.house - 1} » ${planet.house}`;
    }
    return planet.house;
  }

  getHouse(house) {
    return this.houses.find(({ id }) => id === house);
  }

  getPlanetHouse(planet) {
    planet = this.getPlanet(planet);
    return planet.house;
  }

  getSign(sign) {
    if (typeof sign === "string") {
      return this.signs.find(p => p.id === sign);
    }
    return { ...sign, name: SIGN_NAMES[sign.id] };
  }

  getAspectName(aspect) {
    return ASPECT_NAMES[aspect];
  }

  get(id) {
    if (
      LIST_OBJECTS_MODERN.includes(id) ||
      ASTEROID_LIST.includes(id) ||
      ANGLES_LIST.includes(id)
    )
      return this.getPlanet(id);
    else if (SIGN_IDS.includes(id)) return this.getSign(id);
    else return this.getHouse(id);
  }

  getElementDistribution() {
    const calculator = new ElementCalculator(this);
    return calculator.getTotalScore();
  }

  getAscendantSign() {
    return this.getSign(this.angles.find(a => a.id === ASC).sign);
  }

  isHarmonicAspect = aspect => {
    return HARMONIC_ASPECTS.includes(aspect);
  };

  isDynamicAspect = aspect => {
    return DYNAMIC_ASPECTS.includes(aspect);
  };

  isNeutralAspect = aspect => {
    return NEUTRAL_ASPECTS.includes(aspect);
  };

  getAspectNature = aspect => {
    if (this.isHarmonicAspect(aspect)) return HARMONIC_NATURE;
    if (this.isDynamicAspect(aspect)) return DYNAMIC_NATURE;
    if (this.isNeutralAspect(aspect)) return NEUTRAL_NATURE;
    return NO_NATURE;
  };

  getName(id) {
    if (LIST_OBJECTS_MODERN.includes(id) || ASTEROID_LIST.includes(id))
      return OBJECT_NAMES[id];
    else if (SIGN_IDS.includes(id)) return SIGN_NAMES[id];
    else if (ANGLES_LIST.includes(id)) return ANGLES_NAMES[id];
    else return `Casa ${id}`;
  }

  // Get object by type

  getPlanets = () => {
    return this.objects.filter(obj => obj.type === OBJ_PLANET);
  };
}
