import PlanetIcon from "./PlanetIcon";

import { ReactComponent as NorthNodeIcon } from "assets/img/icons/zoodiac/svg/017-north-node.svg";
import { ReactComponent as SouthNodeIcon } from "assets/img/icons/zoodiac/svg/018-south-node.svg";
import { ReactComponent as ChironIcon } from "assets/img/icons/zoodiac/svg/024-chiron.svg";
import { ReactComponent as CeresIcon } from "assets/img/icons/zoodiac/svg/023-ceres.svg";
import { ReactComponent as PallasIcon } from "assets/img/icons/zoodiac/svg/021-pallas.svg";
import { ReactComponent as JunoIcon } from "assets/img/icons/zoodiac/svg/022-juno.svg";
import { ReactComponent as VestaIcon } from "assets/img/icons/zoodiac/svg/020-vesta.svg";
import { ReactComponent as PholusIcon } from "assets/img/icons/zoodiac/svg/019-pholus.svg";
import { ReactComponent as AscIcon } from "assets/icons/astrology-ascendant-bold-400w-300x300.svg";
import { ReactComponent as DescIcon } from "assets/img/icons/zoodiac/svg/010-death.svg";
import { ReactComponent as MCIcon } from "assets/icons/astrology-midheaven-bold-400w-300x300.svg";
import { ReactComponent as ICIcon } from "assets/img/icons/zoodiac/svg/010-death.svg";
import {
  ASC,
  CERES,
  CHIRON,
  DESC,
  IC,
  JUNO,
  MC,
  NORTH_NODE,
  PALLAS,
  PHOLUS,
  SOUTH_NODE,
  VESTA
} from "../../utils/constants";

export default {
  ...PlanetIcon,
  [NORTH_NODE]: NorthNodeIcon,
  [SOUTH_NODE]: SouthNodeIcon,
  [CHIRON]: ChironIcon,
  [CERES]: CeresIcon,
  [PALLAS]: PallasIcon,
  [JUNO]: JunoIcon,
  [VESTA]: VestaIcon,
  [PHOLUS]: PholusIcon,
  [ASC]: AscIcon,
  [DESC]: DescIcon,
  [MC]: MCIcon,
  [IC]: ICIcon
};
