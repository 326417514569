import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Fade,
  Button
} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconLogo from "../../components/Logo/IconLogo";
import Container from "@mui/material/Container";
import bgImage from "../../assets/img/bg2.png";
import { Link } from "react-router-dom";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";
import HiddenJs from "@mui/material/Hidden/HiddenJs";

const carouselData = [
  { title: "Slide 1", text: "Descripción 1", image: "url1.jpg" },
  { title: "Slide 2", text: "Descripción 2", image: "url2.jpg" }
  // Añade más slides según sea necesario
];

const descriptions = [
  "Descubre tu verdadero yo. Nuestros análisis detallados te ofrecen una ventana al autoconocimiento y crecimiento personal, a través de tu carta natal.",
  "Utiliza la astrología como punto de partida para orientar tu mes. Consejos prácticos ajustados a los tránsitos planetarios actuales, enfocados en tus intereses particulares.",
  "Escucha, comprensión y guía. Nuestros consejeros profesionales están aquí para ayudarte a enfrentar tus retos y encontrar soluciones prácticas y efectivas."
];

const Carousel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const gaEventTracker = useAnalyticsEventTracker({
    category: "User Interaction"
  });

  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Apaga el fade
      setTimeout(() => {
        setIndex(prevIndex => (prevIndex + 1) % descriptions.length); // Cambia el índice
        setFade(true); // Enciende el fade
      }, 1400); // Espera 3 segundos antes de cambiar el texto
    }, 10000); // Intervalo total: 3 segundos para mostrar, 3 segundos para ocultar

    return () => clearInterval(interval); // Limpieza al desmontar
  }, []);

  const spacing = isMobile ? 2 : 3;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover"
      }}
      display="flex"
    >
      <Container maxWidth={"lg"}>
        <Box
          mb={3}
          sx={!isMobile ? { height: "100%" } : {}}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={spacing}>
            <Grid item xs={12} md={12}>
              <Grid
                container
                spacing={spacing}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item xs={"auto"} md={"auto"}>
                  <IconLogo size={isMobile ? "lg" : "xl"} />
                </Grid>
                <Grid item xs={12} md>
                  <Typography mb={3} variant={"h3"} style={{ color: "white" }}>
                    Eleva tu bienestar con la astrología: Respuestas inmediatas
                    y reportes personalizados para orientarte.
                  </Typography>
                  <Typography mb={3} variant={"h6"} style={{ color: "white" }}>
                    Comienza con una consulta gratuita y descubre cómo nuestros
                    reportes pueden ayudarte a crecer.
                  </Typography>
                  <Link to={"/chat"}>
                    <Button variant={"contained"} size={"large"}>
                      Haz tu consulta astrológica gratis
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box textAlign={"center"}>
                <Fade in={fade} timeout={2000}>
                  <Typography mt={3} variant={"h6"} style={{ color: "white" }}>
                    {descriptions[index]}
                  </Typography>
                </Fade>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs={12} sm={"auto"}>
                  <Link to={"reports/birth-chart"}>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      color={"primary"}
                      onClick={() => {
                        gaEventTracker(
                          "open_product",
                          "general_report - landing_view"
                        );
                      }}
                    >
                      Reporte General Carta Natal
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={"auto"}>
                  <Link to={"/reports/love"}>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      color={"primary"}
                      onClick={() => {
                        gaEventTracker(
                          "open_product",
                          "love_report - landing_view"
                        );
                      }}
                    >
                      Reporte Amor y Pareja
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={"auto"}>
                  <Link to={"/counceling"}>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      color={"primary"}
                      onClick={() => {
                        gaEventTracker(
                          "open_product",
                          "coaching - landing_view"
                        );
                      }}
                    >
                      Consejería profesional
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={"auto"}>
                  <Button
                    variant={"contained"}
                    size={"small"}
                    color={"primary"}
                    onClick={() => {
                      gaEventTracker("open_product", "custom - landing_view");
                    }}
                    href="https://ig.me/m/astrall.cl?"
                  >
                    Reporte personalizado
                  </Button>
                </Grid>
                {/* <Grid item xs={12} sm={"auto"}>
                  <Link
                    to={{ pathname: "https://tally.so/r/3lqNrB" }}
                    target="_blank"
                  >
                    <Button
                      variant={"contained"}
                      size={"small"}
                      color={"primary"}
                      onClick={() => {
                        gaEventTracker("open_product", "other - landing_view");
                      }}
                    >
                      Otros recursos
                    </Button>
                  </Link>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const LandingCarousel = () => {
  return <Carousel></Carousel>;
};

export default LandingCarousel;
