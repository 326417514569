import useQuery from "../query/useQuery";
import {
  ANGLES_LIST,
  ASC,
  CERES,
  CHIRON,
  JUNO,
  MC,
  NORTH_NODE,
  PALLAS,
  PHOLUS,
  PLANET_LIST,
  SOUTH_NODE,
  VESTA
} from "../../utils/constants";

export default function useObjects(props) {
  const query = useQuery();
  const { chiron, pholus, ceres, pallas, juno, vesta, nodes, asc, mc } = query;
  const objects = [...PLANET_LIST];
  chiron && objects.push(CHIRON);
  pholus && objects.push(PHOLUS);
  ceres && objects.push(CERES);
  pallas && objects.push(PALLAS);
  juno && objects.push(JUNO);
  vesta && objects.push(VESTA);
  nodes && objects.push(NORTH_NODE, SOUTH_NODE);
  asc && objects.push(ASC);
  mc && objects.push(MC);
  return objects;
}
