import Grid from "@mui/material/Grid";
import React from "react";
import { MOON, SUN } from "../../utils/constants";
import SignIcon from "../../components/icons/SignIcon";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledLabel = styled("div")(({ theme }) => ({
  fontSize: "1em",
  fontWeight: "500"
}));

const StyledTitleLink = styled("h6")(({ theme }) => ({
  fontSize: 12,
  color: "#757575"
}));

export default function NCMVMainPlanets({
  chart,
  onSelectPlanet,
  onSelectSign,
  timeIncluded
}) {
  const sunSign = chart.getPlanetSign(SUN);
  const moonSign = chart.getPlanetSign(MOON);
  const ascSign = chart.getAscendantSign();
  const SunSignIcon = SignIcon[sunSign.id];
  const MoonSignIcon = SignIcon[moonSign.id];
  const AscSignIcon = SignIcon[ascSign.id];
  const colWith = timeIncluded ? 4 : 6;
  return (
    <Box>
      <Paper>
        <Box p={1}>
          <Grid container alignItems={"center"}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="h6" color="textPrimary">
                  Planetas principales
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{ fontSize: 14, textAlign: "center" }}>
                <Grid item xs={colWith}>
                  <StyledTitleLink onClick={() => onSelectPlanet(SUN)}>
                    Sol
                  </StyledTitleLink>
                  <StyledLabel>
                    <SunSignIcon height={12} width={12} /> {sunSign.name}
                  </StyledLabel>
                </Grid>
                <Grid item xs={colWith}>
                  <StyledTitleLink onClick={() => onSelectPlanet(MOON)}>
                    Luna
                  </StyledTitleLink>
                  <StyledLabel>
                    <MoonSignIcon height={12} width={12} /> {moonSign.name}
                  </StyledLabel>
                </Grid>
                {timeIncluded && (
                  <Grid item xs={colWith}>
                    <StyledTitleLink>Ascendente</StyledTitleLink>
                    <StyledLabel>
                      <AscSignIcon height={12} width={12} /> {ascSign.name}
                    </StyledLabel>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
