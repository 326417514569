import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import NatalChart from "components/charts/NatalChart";
import Grid from "@mui/material/Grid";
import NCMVMainPlanets from "../NatalChart/NCMVMainPlanets";
import { Link } from "react-router-dom";
import PlanetTable from "../../components/charts/Planet/PlanetTable";
import AspectTable from "../../components/charts/Aspect/AspectTable";
import Box from "@mui/material/Box";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";

export default function ChartDetailsDialog({ user, chart, open, onClose }) {
  // mediaQuery hook to detect if mobile screen using theme breakpoints
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const gaEventTracker = useAnalyticsEventTracker({
    category: "User Interaction"
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={"sm"}
      fullScreen={isMobile}
    >
      <DialogTitle>Carta Natal {user?.name}</DialogTitle>

      <DialogContent>
        <Typography variant={"body2"} color={"textPrimary"} component={"p"}>
          Así es cómo se ve tu carta natal:
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NatalChart
              chart={chart}
              onSelectSign={() => null}
              onSelectHouse={() => null}
              onSelectPlanet={() => null}
              onUnselect={() => null}
              selectedPlanet={null}
              timeIncluded={chart?.time}
            />
          </Grid>
          <Grid item xs={12}>
            <NCMVMainPlanets
              chart={chart}
              onSelectSign={() => null}
              onSelectPlanet={() => null}
              timeIncluded={chart?.time}
            />
            {/*<DistributionTable chart={chart}/>*/}
            {/*
             <Grid container justify={"center"} spacing={1}>
              <Grid item xs={12}>
                <QuadrantDistribution chart={chart}/>
              </Grid>
            </Grid>
            */}
          </Grid>

          <Grid item xs={12}>
            <PlanetTable chart={chart} selectedPlanet={null} />
            <AspectTable
              chart={chart}
              onSelectPlanet={() => null}
              selectedPlanet={null}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Link to={"reports/birth-chart"}>
              <Button
                fullWidth
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => {
                  gaEventTracker("open_product", "general_report - chat_view");
                }}
              >
                Reporte Carta Natal
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Link to={"/reports/love"}>
              <Button
                fullWidth
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => {
                  gaEventTracker("open_product", "love_report - chat_view");
                }}
              >
                Reporte Amor y Pareja
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Link to={"/counceling"}>
              <Button
                fullWidth
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => {
                  gaEventTracker("open_product", "horoscope - chat_view");
                }}
              >
                Pronóstico del futuro
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Link to={"/counceling"}>
              <Button
                fullWidth
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => {
                  gaEventTracker("open_product", "coaching - chat_view");
                }}
              >
                Consejería profesional
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            {/* Button item aligned at right */}
            <Box display="flex" justifyContent="flex-center">
              <Button
                fullWidth
                onClick={onClose}
                variant={"text"}
                color="primary"
                size={"large"}
              >
                Ok
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
