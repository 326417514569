import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import "moment";
import useGeoLocator from "../../hooks/geo/useGeoLocator";
import Autocomplete from "@mui/material/Autocomplete";
import matchSorter from "match-sorter";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";
import PropTypes from "prop-types";

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, { keys: ["displayName"] });
function AppGeoLocalizerInput({
  query,
  location,
  onQueryChange,
  onLocationChange,
  eventTracker,
  ...props
}) {
  const gaEventTracker = useAnalyticsEventTracker({
    category: "User Interaction"
  });
  const {
    data: geoData,
    loading: geoLoading,
    fetchItem: geoFetch,
    canBeFetched
  } = useGeoLocator({ queryParams: { query: query } });

  const handleChange = e => {
    onQueryChange(e.target.value);
  };

  const handleLocationChange = (e, val) => {
    if (eventTracker) gaEventTracker(eventTracker.event, eventTracker.label);
    onLocationChange(val);
  };

  useEffect(() => {
    if (Boolean(geoFetch) && canBeFetched(query)) {
      geoFetch();
    }
  }, [query]);

  return (
    <Autocomplete
      options={geoData || []}
      getOptionLabel={option => option.displayName}
      filterOptions={filterOptions}
      loading={geoLoading}
      onChange={handleLocationChange}
      value={location}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="Lugar de nacimiento"
          variant="outlined"
          name={"geoQuery"}
          onChange={handleChange}
        />
      )}
      {...props}
    />
  );
}

export default AppGeoLocalizerInput;

AppGeoLocalizerInput.propTypes = {
  query: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  eventTracker: PropTypes.shape({
    event: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
};
