const elementColors = {
  aqua: {
    bg: "#8bc9d4",
    primary: "#0D4E59"
  },
  fire: {
    bg: "#d4a7a7",
    primary: "#5a1111"
  },
  earth: {
    bg: "#8ad4a5",
    primary: "#11592B"
  },
  air: {
    bg: "#d4d08a",
    primary: "#595411"
  }
};

const aspectColors = {
  harmonic: {
    light: "#137987",
    dark: "#0D4E59"
  },
  dynamic: {
    light: "#851111",
    dark: "#5a1111"
  },
  neutral: {
    light: "#158538",
    dark: "#11592B"
  }
};

export { elementColors, aspectColors };
