import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoIcon } from "../../assets/img/logo.svg";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import styled from "@emotion/styled";

const styles = {
  white: {
    color: "white"
  },
  black: {
    color: "black"
  },
  sm: {
    width: 36,
    height: 36
  },
  md: {
    width: 54,
    height: 54
  },
  lg: {
    width: 72,
    height: 72
  },
  xl: {
    width: 300,
    height: 300
  }
};

const getSizeStyle = size => {
  switch (size) {
    case "sm":
      return styles.sm;
    case "md":
      return styles.md;
    case "lg":
      return styles.lg;
    case "xl":
      return styles.xl;
    default:
      return {};
  }
};

const getColorStyle = color => {
  switch (color) {
    case "white":
      return styles.white;
    case "black":
      return styles.black;
    default:
      return {};
  }
};

const StyledIcon = styled(SvgIcon)(({ size, color }) => ({
  ...getSizeStyle(size),
  ...getColorStyle(color)
}));

export default function IconLogo({ size, color, ...props }) {
  return (
    <StyledIcon
      component={LogoIcon}
      viewBox={"0 0 1000 1000"}
      fontSize="inherit"
      size={size}
      color={color}
    />
  );
}

IconLogo.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

IconLogo.defaultProps = {
  size: "sm",
  color: "white"
};
