import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import "moment";
import useGeoLocator from "../../hooks/geo/useGeoLocator";
import Autocomplete from "@mui/material/Autocomplete";
import matchSorter from "match-sorter";

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, { keys: ["displayName"] });
export default function GeoLocalizerInput({
  query,
  location,
  onQueryChange,
  onLocationChange,
  ...props
}) {
  const {
    data: geoData,
    loading: geoLoading,
    fetchItem: geoFetch,
    canBeFetched
  } = useGeoLocator({ queryParams: { query: query } });

  const handleChange = e => {
    onQueryChange(e.target.value);
  };

  const handleLocationChange = (e, val) => {
    onLocationChange(val);
  };

  useEffect(() => {
    if (Boolean(geoFetch) && canBeFetched(query)) {
      geoFetch();
    }
  }, [query]);

  return (
    <Autocomplete
      options={geoData || []}
      getOptionLabel={option => option.displayName}
      filterOptions={filterOptions}
      loading={geoLoading}
      onChange={handleLocationChange}
      value={location}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="Lugar de nacimiento"
          variant="outlined"
          name={"geoQuery"}
          onChange={handleChange}
        />
      )}
      {...props}
    />
  );
}
