import React from "react";
import { Helmet } from "react-helmet";

const PDFExampleView = ({ pdfType }) => {
  const isLovePDF = pdfType === "love_pdf_example";
  const title = isLovePDF ? "Love Report PDF" : "General Report PDF";
  const description = isLovePDF
    ? "View our love astrological report in PDF format"
    : "View our general astrological report in PDF format";
  const pdfSrc = isLovePDF ? "/love_report.pdf" : "/general_report.pdf";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://yourdomain.com/path/to/preview-image.jpg"
        />
        <meta property="og:url" content="https://yourdomain.com/pdf-example" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <iframe
        src={pdfSrc}
        style={{
          width: "100%",
          height: "100vh",
          border: "none"
        }}
        title={title}
      />
    </>
  );
};

export default PDFExampleView;
