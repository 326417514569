import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { MessageList, Input, SystemMessage } from "react-chat-elements";
import CustomIconButton from "../../components/Button/CustomIconButton";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import AppButton from "../../components/Button/AppButton";

const PRODUCTS_INFO = {
  "Pronóstico Astrológico": {
    url: 'href="https://ig.me/m/astrall.cl?"',
    eventTracker: {
      action: "open_product",
      label: "horoscope - chat_component"
    }
  },
  "Reporte Astrológico de Amor y Relaciones": {
    path: "/reports/love",
    eventTracker: {
      action: "open_product",
      label: "love_report - chat_component"
    }
  },
  "Reporte Astrológico General": {
    path: "/reports/birth-chart",
    eventTracker: {
      action: "open_product",
      label: "general_report - chat_component"
    }
  },
  "Consejería y Coaching": {
    path: "/counceling",
    eventTracker: { action: "open_product", label: "coaching - chat_component" }
  }
};

export default function Chat({
  messages,
  user,
  handleSend,
  disabled,
  hideInput,
  recommendedProducts,
  onProductClick
}) {
  const [text, setText] = useState("");

  const handleChange = e => {
    setText(e.target.value);
  };

  const _handleSend = () => {
    handleSend(text, () => setText(""));
  };
  const inputDisabled = disabled || !text.trim();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MessageList
            className="message-list"
            lockable={true}
            toBottomHeight={"100%"}
            dataSource={[
              {
                position: "left",
                type: "text",
                title: "Astrall",
                text:
                  "Hola! Soy Astrall, tu asistente astral. ¿En qué puedo ayudarte?"
              },
              ...messages
            ]}
          />
        </Grid>
        {recommendedProducts.size > 0 && (
          <Grid item xs={12}>
            <Grid container justifyContent={"start"} spacing={1} mt={2}>
              <Grid item xs={12}>
                <Typography variant={"caption"} color={"textSecondary"}>
                  Servicios recomendados:
                </Typography>
              </Grid>
              {[...recommendedProducts].map((product, index) => {
                const productInfo = PRODUCTS_INFO[product];
                return (
                  <Grid item key={product}>
                    <AppButton
                      size={"small"}
                      onClick={() => onProductClick(productInfo)}
                      eventTracker={productInfo.eventTracker}
                    >
                      {product}
                    </AppButton>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {!hideInput && (
          <>
            <Grid item xs={true} sm={true}>
              <TextField
                variant={"outlined"}
                fullWidth
                multiline
                rows={1}
                rowsMax={3}
                placeholder="Haz una consulta..."
                value={text}
                onChange={handleChange}
                onKeyPress={e => {
                  if (inputDisabled) return;
                  if (e.key === "Enter") {
                    _handleSend();
                  }
                }}
              />
            </Grid>
            <Grid item xs={"auto"} sm={"auto"}>
              <CustomIconButton
                title="Enviar"
                onClick={_handleSend}
                disabled={inputDisabled}
              >
                <SendIcon />
              </CustomIconButton>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
