import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader
} from "@mui/material";
import IconLogo from "../Logo/IconLogo";
import styled from "@emotion/styled";

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2)
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block"
  },
  [theme.breakpoints.down("sm")]: {
    display: "block"
  },
  textDecoration: "none"
}));

export default function PrimarySearchAppBar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    // flex grow box
    <Box display="flex" flexGrow={1}>
      <AppBar position="static">
        <Toolbar>
          <MenuButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </MenuButton>

          <TitleTypography
            variant="h6"
            color="inherit"
            noWrap
            to="/"
            component={Link}
          >
            <IconLogo />
          </TitleTypography>
          <Box marginLeft="40px" />
          <TitleTypography
            variant="h6"
            color="inherit"
            noWrap
            to="/about-us"
            component={Link}
          >
            ¿Quiénes somos?
          </TitleTypography>
        </Toolbar>
      </AppBar>
      <Drawer open={drawerOpen} onClose={handleDrawerClose}>
        <div
          tabIndex={0}
          role="button"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <Box textAlign="center" p={1}>
            <TitleTypography
              variant="h6"
              color="inherit"
              noWrap
              to="/"
              component={Link}
            >
              <IconLogo color="black" size="md" />
            </TitleTypography>
          </Box>
          <div>
            <List
              component="div"
              subheader={
                <ListSubheader component="div">
                  Reportes Astrológicos
                </ListSubheader>
              }
            >
              <ListItem button component={Link} to="/reports/birth-chart">
                <ListItemText primary="Reporte General de Carta Natal" />
              </ListItem>
              <ListItem button component={Link} to="/reports/love">
                <ListItemText primary="Reporte de Amor y Relaciones" />
              </ListItem>
            </List>
            <Divider />
            <List
              component="div"
              subheader={
                <ListSubheader component="div">Más de Astrall</ListSubheader>
              }
            >
              <ListItem button component={Link} to="/counceling">
                <ListItemText primary={"Consejería profesional"} />
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
      {false && renderMobileMenu}
      {false && renderMenu}
    </Box>
  );
}
